@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components{

   .transformation{
    transform: translateY(360px) scale(0);
    transition: transform ease-in-out 2s;
  }
  .transformation2{
    transform: translateY(-2px);
    transition: transform ease-in-out 2s;
  }
  @media (max-width: 640px) {

  .stars{
    @apply -scale-75 -ml-8 ;
  }

  .celest{
    @apply left-72
  }
    .side-bar {
      @apply bg-slate-50 mt-3 h-8 rounded-2xl items-center justify-start sticky w-screen scale-95 border-b-4 border-l-2 border-black flex ;
  transition: 0.5s;
  padding-top: 0.5px;
  }
  .text{
    @apply items-center
  }
  
  .side-bar-icons{
  @apply h-8 w-8 ml-10 mr-4 mx-auto hover:scale-125 hover:rounded-lg cursor-pointer [padding:2px] scale-90 }
  .aboutme{
  @apply top-48 relative
}
  .left-margin{
    @apply ml-8
  }
  .t-width{
    width: 20rem;
  }
  .scr{
    @apply h-full
  }
  .card-margin{
    @apply ml-8
  }
  .bg-position{
    @apply bg-bottom
  }
  .resumePage{
    @apply w-screen scr-length bg-cover bg-center flex overflow-hidden border-b-2 border-white;
    background-image: url('./Assets/verbg.png');
  }
  .icons{
    @apply mt-4 mb-8;
  }
  .cardpos{
    @apply flex flex-col items-center justify-center w-screen -ml-6 mb-4;
  }
  .cardposcon{
    @apply flex flex-col items-center justify-center w-screen -ml-12 mb-8;
  }
}

@media (min-width: 641px) {
    .side-bar {
      @apply bg-slate-50 ml-6 rounded-2xl h-60 items-center border-l-4 border-t-2 border-black justify-center sticky mt-60 w-12;
transition: 0.5s;
padding-top: 0.5px;
}

.side-bar-icons{
@apply h-8 w-8 mt-6 mb-4 mx-auto hover:scale-125 hover:rounded-lg cursor-pointer
}
.aboutme{
  @apply left-36 top-48 relative
}
.text{
  @apply items-start
}
.left-margin{
  @apply ml-32
}
.t-width{
  width: 35rem;
}
.scr-length{
  @apply h-screen
}
.card-margin{
  @apply ml-56
}
.bg-position{
  @apply bg-center bg-fixed
}
}
  .side-bar-info{
    @apply absolute p-2 m-2 min-w-max left-14 rounded-md shadow-md text-white bg-gray-800 text-xs font-bold transition-all duration-100 origin-left scale-0
  }
  .resumePage{
    @apply w-screen scr-length bg-cover bg-center flex overflow-hidden border-b-2 border-x-yellow-700;
    background-image: url('./Assets/ResBg.jpg');
  }
  .icons{
    @apply mt-4 mb-20;
  }
}